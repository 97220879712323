.Gallery .hero {
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/table-decor2.jpeg");
  background-repeat: no-repeat;
  background-position-y: 70%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* .image-grid-container {
  max-width: 1224px;
  margin: auto;
  padding: 40px 0;
} */

.Gallery h3 {
  color: white;
  font-size: 24px;
  border-radius: 5px;
  font-size: 1.5rem;
}

.Gallery p {
  color: white;
}

.Gallery #content-wrap {
  display: flex;
  flex-direction: column;
}

.Gallery #gallery-link {
  font-weight: bold;
}

#past-events {
  padding: 40px 100px 0 !important;
}

.Gallery .gallery-summary p {
  color: rgb(77, 76, 76);
}

#gallery-summary-container {
  background: rgba(0, 0, 0, 0.1);
}

.Gallery iframe {
  height: 70vh;
  width: 70vh;
}

.Gallery p {
  font-size: 1rem;
  font-weight: 400;
}

.container.past-events h2 {
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin: 0;
}

.container.past-events h3 {
  font-weight: 700;
  line-height: 1.5;
  color: white;
  opacity: 0.8;
  margin: 0;
}

/* mobile browsers */
@media (max-width: 696px) and (orientation: portrait) {
  .Gallery .hero {
    height: 65vh;
  }

  #gallery-summary-container {
    padding: 20px 0 !important;
  }

  #past-events {
    padding: 10px 20px 0 20px !important;
  }

  .container.past-events {
    padding: 0;
  }

  .Gallery h5 {
    font-size: 15px;
    padding: 10px 20px;
    margin: 10px !important;
  }

  .Gallery .row {
    width: 100% !important;
  }
}

@media (max-width: 932px) and (orientation: landscape) {
  .Gallery .hero {
    height: 100vh;
  }

  .Gallery a {
    font-size: 14px;
  }

  #past-events {
    padding: 30px 30px 0 30px !important;
  }

  .container.past-events {
    padding: 0;
  }

  #gallery-summary-container {
    margin: 40px 0 !important;
  }

  .Gallery #content-wrap {
    padding: 0 10px !important;
    margin: 0 auto 10px !important;
  }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 1368px) {
  .Gallery .container {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 932px) and (orientation: landscape) {
  #gallery-container {
    margin-bottom: 0px !important;
  }
}
