.Home .hero {
  background-image: linear-gradient(rgb(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/citrus-party.jpg");
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.Home #home-link {
  font-weight: bold;
}

.Home .card {
  background: white;
  height: auto;
}

.Home .card-header {
  background: white;
  padding: 0;
}

.Home #card-row img {
  background: white;
  display: block;
  margin: 0 auto;
  padding: 30px;
  height: 200px !important;
}

.Home .card-body {
  padding: 10px;
}

.Home .card-body-content {
  padding: 20px 10px;
  height: 250px;
}

.main h2 {
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 5px;
  color: #000;
  text-transform: uppercase;
  margin: 0;
}

.Home .main h3 {
  font-weight: 700;
  line-height: 1.5;
  color: #272044;
  margin: 0;
}

.Home .main h4 {
  font-weight: 700;
  font-size: 24px !important;
  line-height: 1;
}

.section {
  max-width: 100%;
  margin: 0 auto;
  padding: 25px;
}

.section.secondary {
  padding: 100px 0;
}

.section.secondary ul {
  list-style: disc;
}

#banner-home div {
  position: relative;
  height: 500px;
  border-radius: 5px;
}

#banner-home div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/table-decor3.jpeg");
  background-repeat: no-repeat;
  background-position-y: 40%;
  background-size: cover;
  filter: blur(2px);
}

#banner-home p {
  backdrop-filter: blur(5px) grayscale(100%) brightness(50%);
  font-size: 30px;
  line-height: 60px;
  margin-top: 16px !important;
  border-radius: 5px;
  max-width: 1000px;
}

.section.vision {
  background-image: linear-gradient(rgb(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/vision3.jpg");
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: cover;
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.section.vision h5 {
  font-size: 56px;
  font-family: raleway;
}

/* mobile browsers */
@media (max-width: 576px) and (orientation: portrait) {
  .Home .hero {
    height: 65vh;
  }

  .section h3 {
    font-size: 18px !important;
    text-align: center;
  }

  .section p {
    text-align: center;
  }

  #banner-home div {
    height: 50vh;
  }

  #banner-home div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/table-decor3.jpeg");
    background-repeat: no-repeat;
    background-position-y: 15%;
    background-size: cover;
    filter: blur(1px);
    z-index: 0;
  }

  #banner-home p {
    font-size: 16px;
    line-height: 24px;
    z-index: 10;
  }

  .vision.section h5 {
    font-size: 32px;
  }

  .vision.section a {
    font-size: 12px;
  }

  .section.secondary {
    padding: 40px 20px;
  }

  .card {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 75%;
  }

  .Home .card-body-content {
    padding: 20px 10px;
    height: 150px;
  }

  .Home .card-body-content h4 {
    font-size: 14px !important;
  }

  .Home .card-body-content ul {
    font-size: 14px !important;
  }

  .Home #card-row img {
    height: 120px !important;
    padding: 10px;
  }

  .Home #card-row li {
    text-align: left !important;
  }

  .card a {
    font-size: 10px !important;
    padding: 10px !important;
  }

  .Home .main h3,
  h4 {
    font-size: 20px !important;
  }

  .Home ul {
    text-align: left;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .Home .hero {
    height: 100vh;
  }

  .section.vision {
    height: 300px;
  }

  .section.vision h5 {
    font-size: 32px;
    padding: 0 !important;
  }

  .vision.section a {
    font-size: 12px;
  }

  .section {
    padding: 40px;
    text-align: center;
  }

  #availability-btn {
    width: 50%;
  }

  #banner-home div {
    height: 75vh;
  }

  #banner-home div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/table-decor3.jpeg");
    background-repeat: no-repeat;
    background-position-y: 15%;
    background-size: cover;
    filter: blur(1px);
    z-index: 0;
  }

  #banner-home p {
    font-size: 20px;
    line-height: 26px;
    z-index: 10;
  }

  .section.secondary {
    padding: 10px;
  }

  .section.secondary p {
    margin-bottom: 0 !important;
  }

  .Home .main h3,
  h4 {
    font-size: 20px !important;
    text-align: center;
    padding: 0;
  }

  /* .Home .main p {
    padding-top: 0 !important;
    margin-top: 0 !important;
  } */

  .Home ul {
    text-align: left;
  }

  .Home #card-row .row {
    padding: 0;
    margin: 0;
  }

  .Home #card-row img {
    background: white;
    display: block;
    margin: 0 auto;
    padding: 10px;
    height: 90px !important;
  }

  #card-row .card-body {
    padding: 0;
  }

  #card-row .card-body-content {
    padding: 10px 0 0;
    height: 150px;
  }

  #card-row .btn {
    font-size: 12px;
  }

  #card-row h4 {
    font-size: 16px !important;
  }

  #card-row li {
    font-size: 12px !important;
  }

  #card-row .card {
    margin: 10px 0;
  }

  .section.vision {
    background-image: linear-gradient(rgb(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../assets/vision3.jpg");
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-size: cover;
    width: 100%;
  }

  @media (min-width: 2000px) {
    #banner-home div {
      height: 800px !important;
    }
  }
}
